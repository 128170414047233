import { Injectable } from '@angular/core';
import { PendingAction } from './models/pending_action.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PendingActionService {

  public actions_queue: PendingAction[] = [];
  readonly TIME_BETWEEN_TASKS = 5 * 1000; // Send tasks every 5 seconds
  readonly TIME_BETWEEN_RETRY = 15 * 1000; // Retry tasks every 30 seconds 

  constructor(private http: HttpClient) {

    this.restoreFromStorage();
    this.runNextTask();

  }

  restoreFromStorage() {

    const stored = localStorage.getItem("actions_queue");

    if (stored) {
      try {
        this.actions_queue = JSON.parse(stored);
        console.info("Queue restored from localStorage.");
      } catch (e) {
        console.error("Error trying to restore actions queue from localStorage.");
      }
    }

  }

  saveToStorage() {

    localStorage.setItem("actions_queue", JSON.stringify(this.actions_queue));

  }

  create(method: string, url: string, data: string) {

    const new_action = new PendingAction(method, url, data, Date.now());
    this.actions_queue.push(new_action);
    this.saveToStorage();

  }

  runNextTask() {

    if (this.actions_queue.length == 0) {
      //console.info("Queue is empty.");
      setTimeout(() => this.runNextTask(), this.TIME_BETWEEN_TASKS);
      return;
    }

    console.info("Running next task.");

    this.runTask();

  }


  runTask() {

    const task = this.actions_queue[0];
    if (task.method == 'put') {

      this.http.put<any>(task.url, task.data)
        .subscribe(data => {
          console.info("Task run ok:", data);
          this.actions_queue.shift();
          this.saveToStorage();
          setTimeout(() => this.runNextTask(), this.TIME_BETWEEN_TASKS);
        },
          error => {
            console.error("Task run error:", error);
            setTimeout(() => this.runNextTask(), this.TIME_BETWEEN_RETRY);
          }
        );

    }

  }

}