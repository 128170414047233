import { Injectable } from "@angular/core";
import { Adapter } from "../adapter";

export class LMSProfile {
    constructor(
      public first_name: string,
      public last_name: string,
      public email: string,
      public country: string,
      public years_tattooing: number,
      public instagram: string,
      public website: string,
      public id_number: string,
      public newsletter_subscribed: boolean,
      public favourited_lesson: number[],
      public favourited_topic: number[],
      public favourited_library_entry: number[],
      public completed_lesson: number[],
      public completed_topic: number[],
      public review_lesson: number[],
      public review_topic: number[],
      public enrollments: [],
      public avatar: string,
      public text_language: string | null,
      public audio_language: string | undefined,
      public library_access : boolean = false,

      // Internal properties
      public last_fetch : number | null           // Date of the latest update from server
    ) {}
  }

@Injectable({
    providedIn: "root",
})

export class LMSProfileAdapter implements Adapter<LMSProfile> {
    adapt(item: any): LMSProfile {

        const now = Date.now();
        return new LMSProfile(item.first_name, item.last_name, item.email, item.country, item.years_tattooing, item.instagram, item.website, item.id_number, item.newsletter_subscribed, item.favourited_lesson, item.favourited_topic, item.favourited_library_entry, item.completed_lesson, item.completed_topic, item.review_lesson, item.review_topic, item.enrollments, item.avatar, item.text_language, item.audio_language, item.library_access,
          now);
    }
}