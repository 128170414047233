import { Injectable } from "@angular/core";
// import { Adapter } from "../adapter";

export class PendingAction {
    constructor(
      public method : string,
      public url: string,
      public data: string,
      public date: number
    ) {}
  }

/*
@Injectable({
    providedIn: "root",
})
export class PendingActionAdapter implements Adapter<PendingAction> {

    adapt(item: any): PendingAction {
        return new PendingAction(item.url, item.data, item.date);
    }

}
*/