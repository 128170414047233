import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';

//import { SwiperModule } from 'swiper/angular';


import { HttpClientModule } from '@angular/common/http'; // JWT
import { FormsModule } from '@angular/forms'; // JWT
import { UserService } from './user.service';

//import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
//import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';


// FOR TRANSLATIONS
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { TapticEngine } from '@awesome-cordova-plugins/taptic-engine/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { FormBuilder } from '@angular/forms';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

import { NgCircleProgressModule } from 'ng-circle-progress';

import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';

import { File } from '@awesome-cordova-plugins/file/ngx';

import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

//import { LottieSplashScreen } from '@awesome-cordova-plugins/lottie-splash-screen/ngx';

import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';

import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen/ngx';

import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';

//import { WebServer } from '@awesome-cordova-plugins/web-server/ngx';

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { PinchZoomModule } from '@mapaxe/ngx-pinch-zoom';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, FormsModule, HttpClientModule, LottieModule.forRoot({ player: playerFactory }),/*LottieSplashScreen,*/ /*SwiperModule,*/
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
      NgCircleProgressModule.forRoot({
        // set defaults here
        radius: 100,
        outerStrokeWidth: 25,
        innerStrokeWidth: 1,
        outerStrokeColor: "#ff4961",
        innerStrokeColor: "white",
        animationDuration: 500,
        //titleFontSize: '50',
        subtitle: ''
      }),
      PinchZoomModule,
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, UserService, InAppBrowser,
              //WebServer,
    TapticEngine, ImagePicker, FormBuilder, LocalNotifications, FileTransfer, File, FileOpener, Deeplinks, AndroidFullScreen,
                  Keyboard, Insomnia,//LottieSplashScreen,
        {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
