import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { LMSProfileService } from './lmsprofile.service';
import { LMSProfile } from './models/lmsprofile.model';

import { Platform } from '@ionic/angular';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

import { TranslateService } from '@ngx-translate/core';

import { ToastController } from '@ionic/angular';

import { PendingActionService } from './pending-action.service';


 
@Injectable(/*{ providedIn: 'root' }*/)
export class UserService {

  private baseURL = 'https://www.10masters.com';
 
  // http options used for making API calls
  private httpOptions: any;
 
  // the actual JWT token
  public token: string | null = null;
 
  // the token expiration date
  public token_expires: Date | null = null;
 
  // the username of the logged in user
  public username: string = "";

  public lmsprofile: LMSProfile | null = null;

  public app_language = "en";
  
  public unread_messages = 0;
 
  // error messages received from the login attempt
  public errors: any = [];

  public translations : any = [];

  // 12 hours
  readonly MAX_SECONDS_SINCE_LAST_LMSPROFILE_UPDATE =   1000 /* milliseconds */
                                                      * 60 /* seconds */
                                                      * 60 /* minutes */
                                                      * 12 /* hours */;

                                                  
  public disable_typing_effects = false;
 
  constructor(private lmsProfileService: LMSProfileService, private router: Router, private http: HttpClient,
              private localNotifications: LocalNotifications, public platform: Platform, private translate: TranslateService,
              private toastController: ToastController, private pendingActionService : PendingActionService ) {
    

    if ( ! this.restoreFromLocalstorage() ){
      //this.router.navigate(['/tabs/login']);
    }

    this.getAppLanguage();

    this.httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };

    this.translate.get('Welcome').subscribe(
      value => {
        this.translations['Welcome'] = value;
      }
    )

    this.translate.get('userservice.You have unread messages').subscribe(
      value => {
        this.translations['You have unread messages'] = value;
      }
    )

  }

  public setAppLanguage(lang:string) {

    localStorage.setItem("app_language", lang);
    this.app_language = lang;
    this.translate.use(lang);

  }

  public getAppLanguage() {

    const stored = localStorage.getItem("app_language");
    
    if ( stored ) {
      this.app_language = stored;
      this.translate.use(stored);
      console.info("getAppLanguage is from storage: " + stored);
      return stored;
    }

    const navig = navigator.language.split('-')[0];

    this.app_language = navig;
    this.translate.use(navig);

    console.info("getAppLanguage is from navigator: " + navig);

    return navig;

  }

  public setUnreadMessages(num:number) {

    /*const channel1: NotificationChannel = {
      id: 'mychannel',
      name: 'channel',
      importance: 5,
      sound: 'sound.mp3',
      visibility: 1,
      
    }*/

    this.unread_messages = num;

    // Disable for now, since it seems to crash some Android versions (Xiaomi?) ...
    return;

    // New unread messages compared to before
    if ( this.unread_messages == 0 && num > 0 ) {

      this.localNotifications.on('click').subscribe(data => {
        if ( data.type == "messages" ) {
          this.router.navigate(["/tabs/tab_messages"]);
          this.localNotifications.cancelAll();
        }
      });
        
      this.localNotifications.schedule({
        id: 1,
        //channel: 'channel0',
        priority: 2,
        text: this.translations['You have unread messages'],
        sound: this.platform.platforms().includes("ios") ? 'file://assets/10m_notification.caf' : 'tenm_notification.mp3',
        data: {type: "messages", number: num}
      });

    } else {

      this.localNotifications.cancelAll();

    }


  }

  public saveLMSProfile() {

    localStorage.setItem('lmsprofile', JSON.stringify(this.lmsprofile));
    console.info("localStorage LMSProfile updated.");

  }

  public getLMSProfile() {

    return this.lmsProfileService.get().subscribe((lmsprofile: LMSProfile) => {
      this.lmsprofile = lmsprofile;
      localStorage.setItem('lmsprofile', JSON.stringify(this.lmsprofile));
      console.info("LMSProfile updated from server.");
    });

  }

  public updateLMSProfile() {

    localStorage.setItem("disable_typing_effects", JSON.stringify(this.disable_typing_effects));

    return this.lmsProfileService.update({"username": this.username,  first_name: this.lmsprofile?.first_name, last_name: this.lmsprofile?.last_name, country: this.lmsprofile?.country, years_tattooing: this.lmsprofile?.years_tattooing, instagram: this.lmsprofile?.instagram, website: this.lmsprofile?.website, id_number: this.lmsprofile?.id_number, newsletter_subscribed: this.lmsprofile?.newsletter_subscribed, text_language: this.lmsprofile?.text_language, audio_language: this.lmsprofile?.audio_language})
    .subscribe((lmsprofile: LMSProfile) => {
      this.lmsprofile = lmsprofile;
      localStorage.setItem('lmsprofile', JSON.stringify(this.lmsprofile));
    });

  }

  public loginFromCookies() {
    console.info("loginFromCookies() called")
    //this.http.get(this.baseURL + '/serializer/get_token_from_cookie/').subscribe(

    //let sessionid = document.cookie;

    //this.http.get('https://www.10masters.com/serializer/get_token_from_cookie/', { withCredentials: true }).subscribe(
    return this.http.get(this.baseURL + '/serializer/get_token_from_cookie/', { withCredentials: true }).subscribe(
      
      data => {
        //console.info(data);
        this.updateData((data as any)['token']);
        this.startRefreshTokenTimer();
        this.getLMSProfile();
        this.router.navigate(['/tabs/tab1']);
      },
      err => {
        console.error(err);
        this.errors = err['error'];
      }
    );
  }

  async presentLoginToast() {

    const toast = await this.toastController.create({
      message: this.translations['Welcome'] + ', ' + this.lmsprofile?.first_name,
      duration: 1300,
      position: 'middle',
      color: 'danger',
      icon: 'checkmark'
    });

    await toast.present();

  }

  sendUserToken( token : string ) {

    console.info("Sending token to 10m server...");

    return this.http.post(this.baseURL + '/serializer/set_user_token/', JSON.stringify({"token": token}), this.httpOptions).subscribe(
      data => {
        console.info("OK.", data);
      },
      err => {
        console.error(err); // Resend?
      }
    );

  }
 
  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
  public login(user:any) {
    return this.http.post(this.baseURL + '/api-token-auth/', JSON.stringify(user), this.httpOptions).subscribe(
      data => {
        this.updateData((data as any)['token']);
        this.startRefreshTokenTimer();
        this.getLMSProfile().add(

          () => {
            this.presentLoginToast();
            this.router.navigate(['/tabs/tab1']);
          }

        );
      },
      err => {
        this.errors = err['error'];
      }
    );
  }
 
  // Refreshes the JWT token, to extend the time the user is logged in
  public refreshToken() {
    console.info("refreshToken()");
    this.http.post(this.baseURL + '/api-token-refresh/', JSON.stringify({token: this.token}), this.httpOptions).subscribe(
      data => {
        this.updateData((data as any)['token']);
        this.startRefreshTokenTimer();
      },
      err => {
        this.errors = err['error'];
      }
    );
  }
 
  public logout() {

    this.stopRefreshTokenTimer();
    this.token = null;
    this.token_expires = null;
    this.username = "";
    localStorage.clear();

    let isApp = document.URL.startsWith("http://localhost/") || document.URL.startsWith("capacitor");

    if ( !isApp ) {

      location.href="https://www.10masters.com/es/accounts/logout/";

    }

  }

  public restoreFromLocalstorage () {
    
    this.username = localStorage.getItem('username') ?? "";
    this.token = localStorage.getItem('token');

    const l_disable_typing_effects = localStorage.getItem("disable_typing_effects");

    if ( l_disable_typing_effects )
      this.disable_typing_effects = JSON.parse(l_disable_typing_effects);

    try {
      this.lmsprofile = JSON.parse(localStorage.getItem('lmsprofile') ?? "");
    } catch (e) {
      console.info("Cannot restore lmsprofile from localStorage JSON");
      return false;
    }

    if ( ! this.token ||  ! this.username ) {
      console.info("restoreFromLocalstorage => not logged in");
      return false;
    }

    const token_parts = this.token.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));
    this.token_expires = new Date(token_decoded.exp * 1000);
    this.startRefreshTokenTimer();

    //this.router.navigate(['/tabs/tab1']);

    return true;
  }
 
  private updateData(token:string) {
    this.token = token;
    this.errors = [];
 
    // decode the token to read the username and expiration timestamp
    const token_parts = this.token.split(/\./);
    const token_decoded = JSON.parse(window.atob(token_parts[1]));
    this.token_expires = new Date(token_decoded.exp * 1000);
    this.username = token_decoded.username;

    localStorage.setItem('username', this.username);
    localStorage.setItem('token', this.token);
  }

  private refreshTokenTimeout : any = null;

  private startRefreshTokenTimer() {
    console.info("startRefreshTokenTimer called")

    if ( ! this.token_expires ) {
      this.logout();
      this.router.navigate(['/tabs/login']);
    }

    const expires = this.token_expires;

    if ( expires == null) {
      this.logout();
      this.router.navigate(['/tabs/login']);
      return;
    }

    const timeout = expires.getTime() - Date.now() - (60 * 1000);

    // A minute or less to expire
    if ( timeout <= 5000 ) {
      this.logout();
      this.router.navigate(['/tabs/login']);
      return;
    }

    // A minute or less to expire
    if ( timeout <= 60000 ) {
      this.refreshToken();
      return;
    }

    console.info("startRefreshTokenTimer timeout in", timeout / 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // Checks if we should get the LMS Profile and fetches if necessary
  checkShouldUpdateLMSProfile() {

    console.info("checkShouldUpdateLMSProfile()");

    if ( this.lmsprofile && this.lmsprofile.last_fetch && this.lmsprofile.last_fetch < Date.now() - this.MAX_SECONDS_SINCE_LAST_LMSPROFILE_UPDATE ) {

      console.info("Yes. Updateing LMSProfile...");
      this.getLMSProfile();

    }

  }

  markLessonCompleted( id:number ) {

    if ( !this.lmsprofile?.completed_lesson.includes(id) ) {
      this.lmsprofile?.completed_lesson.push(id);
      this.saveLMSProfile();
    }

  }

  markTopicCompleted( id:number ) {

    if ( !this.lmsprofile?.completed_topic.includes(id) ) {
      this.lmsprofile?.completed_topic.push(id);
      this.saveLMSProfile();
    }

  }

 
}