import { Component, EventEmitter, ViewChild } from '@angular/core';
import { UserService } from '../user.service';
import { Event, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { FormBuilder } from '@angular/forms';
import { File, FileEntry } from '@awesome-cordova-plugins/file/ngx';

import { AlertController } from '@ionic/angular';

import { ToastController } from '@ionic/angular';

import { FormBuilder, FormGroup } from '@angular/forms';

import { Platform } from '@ionic/angular';
//import { EventManager } from '@angular/platform-browser';

@Component({
  selector: 'app-tab3',
  templateUrl: 'tab3.page.html',
  styleUrls: ['tab3.page.scss']
})

export class Tab3Page {

  private easternCount = 0;

  public display_save = false;
  public translations : any = [];

  public isApp = true;

  public language_changed = false;

  public uploadForm : FormGroup | undefined;
  
  @ViewChild('avatarInput', {static: false}) avatarInput : any;

  constructor(private router: Router, public _userService: UserService, private translate: TranslateService,
            private imagePicker: ImagePicker, private http: HttpClient, private file: File, private alertController: AlertController,
            private toastController: ToastController, private formBuilder: FormBuilder, private platform: Platform) {

    this.isApp = document.URL.startsWith("http://localhost/") || document.URL.startsWith("capacitor");
    this.translate.setDefaultLang('en');
    this.translate.use(this._userService.getAppLanguage());

  }
  
  ngOnInit() {

    this.uploadForm = this.formBuilder.group({
      avatar: ['']
    });

    if ( !this._userService.token ) {
      this.router.navigate(['/tabs/login']);
      return;
    }

    this.translate.get('profile.Settings saved.').subscribe(
      value => {
        this.translations['Settings saved.'] = value;
      },
      error => {
        this.translations['Settings saved.'] = 'Settings saved.';
      }
    )

    this.translate.get('profile.Are you sure?').subscribe(
      value => {
        this.translations['Are you sure?'] = value;
      }, error => {
        this.translations['Are you sure?'] = 'Are you sure?';
      }
    )

    this.translate.get('profile.No').subscribe(
      value => {
        this.translations['No'] = value;
      }, error => {
        this.translations['No'] = 'No';
      }
    )

    this.translate.get('profile.Yes').subscribe(
      value => {
        this.translations['Yes'] = value;
      }, error => {
        this.translations['Yes'] = 'Yes';
      }
    )

  }

  ionViewDidEnter() {
    this._userService.checkShouldUpdateLMSProfile();
  }

  async showSavedToast() {

    const toast = await this.toastController.create({
      message: this.translations['Settings saved.'],
      duration: 500,
      position: 'bottom',
      color: 'danger',
      icon: 'checkmark'
    });

    await toast.present();

  }

  public saveChanges() {

    this.display_save = false;

    this._userService.setAppLanguage(this._userService.app_language);

    this._userService.updateLMSProfile().add(
      
      () => {

        this.showSavedToast();

        if ( this.language_changed ) {
          
          setTimeout(() => window.location.href="/", 500);
          return;
        }

    });

  }

  public async logout() {

      const alert = await this.alertController.create({
        header:  this.translations['Are you sure?'],
        cssClass: 'custom-alert',
        buttons: [
          {
            text:  this.translations['No'],
            cssClass: 'alert-button-cancel',
          },
          {
            text:  this.translations['Yes'],
            cssClass: 'alert-button-confirm',
            handler: () => {
              this._userService.logout();
              this.router.navigate(['/tabs/login']);
            }
          },
        ],
      });
  
      await alert.present();

  }

  public refreshProfile(event:any) {

    this._userService.getLMSProfile().add(() => event.target.complete());

  }

  public onAvatarSelect(event:any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm?.get('avatar')?.setValue(file);

      const formData = new FormData();
      formData.append('avatar', this.uploadForm?.get('avatar')?.value);
      this.uploadImageData(formData);
    }

  }

  // See https://devdactic.com/ionic-4-image-upload-storage
  public changeAvatar() {

    this.avatarInput.nativeElement.click();

    return;
    // Disabled for now

    //if ( !this.isApp ) {
    if ( !this.isApp || this.platform.is('ios') ) {

      this.avatarInput.nativeElement.click();
      return;
      
    }

    

    /*this.imagePicker.hasReadPermission().then(
      (result) => {
        if ( result == false ) {
          alert("Requesting permissions");
          this.imagePicker.requestReadPermission().then(() => this.getPictures() );
        } else {
          this.getPictures();
        }
      }
    );*/

    this.getPictures();

  }

  private getPictures() {

    this.imagePicker.getPictures({maximumImagesCount: 1, outputType: 0, title: "Select avatar"}).then((results) => {

      console.info("Trying to resolve file:", results[0]);
      this.startUpload(results[0]);

    }, (err) => {
      
      console.error(err);
      alert(err);

    });

  }

  private startUpload(imgEntry:string) {

    console.info("startUpload");
    
    this.file.resolveLocalFilesystemUrl(imgEntry)
        .then(entry => {
            ( <FileEntry> entry).file(file => this.readFile(file))
        })
        .catch(err => {
            //this.presentToast('Error while reading file.');
            console.error(err);
        });
  }

  private readFile(file: any) {

    console.info("readFile", file)

    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];

    // See https://github.com/ionic-team/capacitor/issues/1564
    const reader = zoneOriginalInstance || fileReader;


    reader.onerror = function(error:any) {console.error(error)};
    reader.onloadstart = function (ev:any) {console.info("onloadstart")};
    reader.onloadend = () => {

        console.info("reader.onloadend ");
        const formData = new FormData();
        const imgBlob = new Blob([reader.result], {
            type: file.type
        });
        formData.append('avatar', imgBlob, file.name);
        this.uploadImageData(formData);
    };
    reader.readAsArrayBuffer(file);

    console.info("readFile end")

  }

  private uploadImageData(formData: FormData) {

    console.info("uploadImageData");
    console.info(formData);

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('enctype', 'multipart/form-data');

    let options = { headers: headers };

    this.http.post("https://www.10masters.com/serializer/avatar_upload/", formData, options).subscribe(
      (res) => {
        console.info(res);
        this._userService.getLMSProfile();
      },
      (err) => {  
        console.log(err);
      }
    );
    
  }

  public deleteAccount() {

    if ( ! confirm(this.translations['Are you sure?']) )
      return;

    if ( prompt('Account deletion will include all of your purschases, progressions in courses, notes, etc. and is immediate and irreversible. Please type to confirm: "I am very sure"')?.toLowerCase() != "I am very sure" ) {
      alert("Deletion canceled.");
      return;
    }

    this.http.delete("https://www.10masters.com/serializer/delete_account/").subscribe(
      (res) => {
        console.info(res);
        alert('Your account has been deleted.');
        this.logout();
      },
      (err) => {  
        console.log(err);
        alert("There was an error: " + err.statusText);
      }
    );

  }

  public eastern() {

    this.easternCount++;

    if ( this.easternCount == 15 ) {
      alert("With LOVE from GINESTAR");
      this.easternCount = 0;
    }

  }

}
