import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { LMSProfile, LMSProfileAdapter } from "./models/lmsprofile.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})

export class LMSProfileService {

  private baseUrl = "https://www.10masters.com/serializer/lmsprofile";

  constructor(private http: HttpClient, private adapter: LMSProfileAdapter) {}

  get(): Observable<LMSProfile> {
    const url = `${this.baseUrl}/`;
    return this.http.get(url).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

  update(body:any): Observable<LMSProfile> {
    const url = `${this.baseUrl}/`;

    return this.http.patch(url, body).pipe(
      map((data: any) => this.adapter.adapt(data))
    );
  }

}