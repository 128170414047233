import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NavController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';


import { Tab3PageModule } from './tab3/tab3.module';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'course/:id/:idBlock',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'course/:id/:idBlock/topic/:idTopic',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule)
  },{
    path: 'course/:id/:idBlock/lesson/:idLesson',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'course-index/:id',
    loadChildren: () => import('./course-index/course-index.module').then( m => m.CourseIndexPageModule)
  },
  {
    path: 'course-block-index/:id/:idBlock',
    loadChildren: () => import('./lesson-block-index/block-index.module').then( m => m.BlockIndexPageModule)
  },
  {
    path: 'message/:id',
    loadChildren: () => import('./message/message.module').then(m => m.MessagePageModule)
  },
  {
    path: 'favourites/:id',
    loadChildren: () => import('./favourites/favourites.module').then( m => m.FavouritesPageModule)
  },
  {
    path: 'certificates/:id/:language',
    loadChildren: () => import('./certificates/certificates.module').then( m => m.CertificatesPageModule)
  },
  {
    path: 'notes/:id',
    loadChildren: () => import('./notes/notes.module').then( m => m.NotesPageModule)
  },
  {
    path: 'note/:id',
    loadChildren: () => import('./note/note.module').then( m => m.NotePageModule)
  },
  {
    path: 'course-language/:id',
    loadChildren: () => import('./course-language/course-language.module').then( m => m.CourseLanguagePageModule)
  },
  {
    path: 'course-language/:id/:mandatory',
    loadChildren: () => import('./course-language/course-language.module').then( m => m.CourseLanguagePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'audio-subtitles/:idCourse',
    loadChildren: () => import('./pages/course-audio-subtitles/course-audio-subtitles.module').then( m => m.CourseAudioSubtitlesPageModule)
  },
  {
    path: 'error-report/:course/:page',
    loadChildren: () => import('./error-report/error-report.module').then( m => m.ErrorReportPageModule)
  },
  {
    path: 'loginSuccess',
    loadChildren: () => import('./login-success-animation/login-success-animation.module').then( m => m.LoginSuccessAnimationPageModule)
  },
  {
    path: 'preview-player',
    loadChildren: () => import('./pages/preview-player/preview-player.module').then( m => m.PreviewPlayerPageModule)
  },
  {
    path: 'upload-content/:idPage',
    loadChildren: () => import('./pages/upload-content/upload-content.module').then( m => m.UploadContentPageModule)
  },
  {
    path: 'course-chat/:idCourse',
    loadChildren: () => import('./course-chat/course-chat.module').then( m => m.CourseChatPageModule)
  },
  {
    path: 'ticket/:idTicket',
    loadChildren: () => import('./ticket/ticket.module').then( m => m.TicketPageModule)
  },
  {
    path: 'library-entry/:id',
    loadChildren: () => import('./library-entry/library-entry.module').then( m => m.LibraryEntryPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

  constructor(private _platform: Platform, private deeplinks: Deeplinks, private navController: NavController) {
  }

  ngAfterViewInit() {
    this._platform.ready().then(() => {

      console.info("PLATFORM READY");

      this.deeplinks.routeWithNavController(this.navController, {
        '/es/mi-cuenta/': Tab3PageModule,
        '/en/my-account/': Tab3PageModule,
      }).subscribe(match => {
          // match.$route - the route we matched, which is the matched entry from the arguments to route()
          // match.$args - the args passed in the link
          // match.$link - the full link data
          console.log('Successfully matched route', match);
        }, nomatch => {
          // nomatch.$link - the full link data
          console.error('Got a deeplink that didn\'t match', nomatch);
        });
      
    });
  }


}
