import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from './user.service';

import { Platform } from '@ionic/angular';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

import { Router } from '@angular/router';

//import { WebServer } from '@awesome-cordova-plugins/web-server/ngx';

//import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';


import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';

//import { LottieSplashScreen } from '@awesome-cordova-plugins/lottie-splash-screen/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  /**
   * An object representing the user for the login form
   */

  constructor(/*private lottieSplashScreen: LottieSplashScreen, */
    private platform: Platform,
    private translate: TranslateService,
    private _userService: UserService,
    private iab: InAppBrowser,
    private router: Router,
    //private webServer: WebServer
  ) {


    const storage_version = localStorage.getItem('storage_version');
    const current_version = "0.700";

    if ( storage_version == undefined || storage_version != current_version ) {

      console.warn("localStorage from older version. Deleting all caches.");

      localStorage.removeItem('block_current_page');
      localStorage.removeItem('course_index');
      localStorage.removeItem('course_list');
      localStorage.removeItem('course_list_last_update');
      localStorage.setItem("storage_version", current_version);

    }

    //this.lottieSplashScreen.hide();
    //this.lottieSplashScreen.show("public/assets/lottieFile/splash.json", false, 768, 1024)
    //.then((res: any) => console.log(res))
    //.catch((error: any) => console.error(error));

    //this.showSplash();

    //console.warn('*NOT Starting web server');
    //this.startWebServer();

  }
/*
  getRealPath (path : string)  {

    return Filesystem.getUri({
      directory: Directory.Documents,
      path: path,
    }).then(
      (getUriResult) => {
        const path = getUriResult.uri;
        console.log("The file's path will be: " + path);
        return path;
      },
      (error) => {
        console.error(error);
      }
    ).then( res => {return res; } );

  }

  async readFilePath ( path : string ) {
    // Here's an example of reading a file with a full file path. Use this to
    // read binary data (base64 encoded) from plugins that return File URIs, such as
    // the Camera.

    const contents = await Filesystem.readFile({
      //path: 'file:///var/mobile/Containers/Data/Application/22A433FD-D82D-4989-8BE6-9FC49DEA20BB/Documents/text.txt',
      path: path,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    });
  
    return contents.data;

  };

  private startWebServer() {

    this.webServer.onRequest();

    this.webServer.onRequest().subscribe(
      async (request) => {

        const file_path = request.path.replace('stream_0', 'stream_1').replace('stream_2', 'stream_1');
        const real_path = await this.getRealPath(file_path).then( res => {return res}) as string;
        const final_path = '/' + real_path.split(':/')[1];

        console.info('REPLACED final_path is ' + final_path);

        this.webServer.sendResponse(
          request.requestId,
          {
            status: 200,
            path: final_path,
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        );

      }
    );

    this.webServer.start(1986).catch((error: any) => console.error(error));

  }
  
  */

  private async showSplash() {

    await this.platform.ready();

    const lottie = (window as any).lottie;

    if (this.platform.is('ios') && lottie) {

      await lottie.splashscreen.hide();
      await lottie.splashscreen.show("public/assets/lottieFile/splash.json", false);

    }


  }

  ngOnInit() {

    this.translate.setDefaultLang('en');
    this.translate.use(this._userService.getAppLanguage());
    //this.translate.use('en');
    //this.translate.get('tab1.tabName').subscribe((text: string) => { console.log('HERE: ', text); });



    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        console.warn("Push notifications permissions NOT granted by user. Unregistering");
        PushNotifications.unregister();
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.info('Push registration success, token: ' + token.value);
        this._userService.sendUserToken(token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.error('Error on registration: ' + JSON.stringify(error) + ". Unregistering...");
        PushNotifications.unregister();
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.info('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        
        console.info('Push action performed: ' + JSON.stringify(notification));

        if (notification.notification.data['openurl']) {

          const browser = this.iab.create(notification.notification.data['openurl']);
          browser.show();

        }

        if (notification.notification.data['openroute']) {

          this.router.navigate([notification.notification.data['openroute']]);

        }

      }
    );

  }

}